<template>
    <form autocomplete="off" class="">
        <h5>You have selected {{this.selected.length}} user{{this.selected.length > 1 ? 's':'' }} to send meeting invitation</h5>
        <div class="row gy-3">
            <div class="col-12">
                <label class="form-label">
                    Meeting <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.meeting_id"
                name="meeting_id" placeholder="--select meeting--">
                    <option disabled value="">--select meeting--</option>
                    <option v-for="meeting in meetings" :key="meeting.id"
                        :value="meeting.id">{{meeting.friendly_id}} - {{meeting.title}}</option>
                </select>
            </div>  
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="sendInvitation()"
                     type="button">Send Invitation</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    props:{
        selected:{
            type: Array
        }
    },
    data(){
        return{
            data: {
                meeting_id:"",
            },
        }
    },
    computed:{
        meetings(){
            return this.$store.state.meeting.list
        }
    },
    methods:{
        sendInvitation() {
            if(!this.data.meeting_id) { this.alertError("Select a meeting"); return;}
            Swal.fire({
                title: "Are you sure?",
                text: "Please confirm this action before proceed",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#1abc9c",
                cancelButtonColor: "#111",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    const formData = new FormData()
                    formData.append('data', JSON.stringify({guests: this.selected, meeting_id: this.data.meeting_id}))
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.post('/meeting-guests/create', formData)
                    .then(response => {
                        this.$store.dispatch("changeLoaderValue",false)
                        if(response.data.success){
                            this.$emit('closeMe');
                        }
                    })
                }
            });
        
        },
    }
}
</script>