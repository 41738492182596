
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4 d-flex">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultRole" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by role --</option>
              <option v-for="(role, tIndex) in roleOptions"
                      :value="role.value" :key="tIndex" > {{role.label}} </option>
            </select>
          </div>
          <div class="dropdown no-arrow ms-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click="selectAllRows()" class="dropdown-item" type="button">
                  {{isSelectAll? 'Invert selection' : 'Select all' }}
                </button>
              </li>
              <li @click.prevent="sendInvitation()">
                <button class="dropdown-item" type="button">
                  Send Invitation
                </button>
              </li>
              <li @click.prevent="exportClients()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchUsers()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(check)="data">
                <div style="width:3rem;" class="form-check text-center me-auto">
                  <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">0{{ data.item.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(lga)="data">
                <div v-if="data.item.lga" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.lga.name }}</span>
                </div>
              </template>
              <template v-slot:cell(ward)="data">
                <div v-if="data.item.ward" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.ward.name }}</span>
                </div>
              </template>
              <template v-slot:cell(unit)="data">
                <div v-if="data.item.unit" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.unit.name }}</span>
                </div>
              </template>
              <template v-slot:cell(tmembers)="data">
                <div @click="navigateTo(data.item.id)" v-if="data.item.group" class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.group.members_count }}</span>
                </div>
              </template>
              <template v-slot:cell(vmembers)="data">
                <div @click="navigateTo(data.item.id)" v-if="data.item.group" class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.group.verified_members_count }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.status }}</span>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <ul class="list-inline table-action m-0">
                  <li class="list-inline-item">
                    <a href="javascript:void(0);" v-if="data.item.id != activeUser.id" @click="deleteItem(data.item.id)" class="text-danger">
                      <i class="ri-delete-bin-2-line"></i></a>
                  </li>
                </ul>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
    <b-modal hide-footer v-model="popupModalInvitation" title="Invitation form">
      <meeting-invitation-form v-if="popupModalInvitation" 
        @closeMe="initializeAll()" :selected="selected"/>
    </b-modal>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import MeetingInvitationForm from '@/components/forms/MeetingInvitationForm.vue';

export default {
  name: "user-list",
  components:{
    IsLoading,
    MeetingInvitationForm,
  },
  data() {
    return {
      isLoading: true,
      defaultRole: null,
      popupModalInvitation: false,
      searchQuery: "",
      roleOptions:[
        {value: null, label: "All", },
        {value: "admin", label: "Admin"},
        {value: "head", label: "Group Head"},
        {value: "user", label: "Member"},
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "Check"
        },
        {
            key: "image",
            label: "Image",
        },
        {
            key: "name",
            label: "Name",
        },
        {
            key: "mobile",
            label: "Phone",
        },
        {
            key: "lga",
            label: "LGA",
        },
        {
            key: "ward",
            label: "Ward",
        },
        {
            key: "unit",
            label: "Unit",
        },
        {
            key: "tmembers",
            label: "Total",
        },
        {
            key: "vmembers",
            label: "Verified",
        },
        {
            key: "status",
            label: "Status",
        },
        "action"
      ],
      selected: [],
      selectedUsers: []
    }
  },
  watch: {
    "$route.query" : "refreshQueryRole",
    defaultRole (val) {
      this.fetchUsers()
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.userList.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchUsers(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchUsers(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    refreshQueryRole(){
      if(this.$route.query.role){
        this.defaultRole = this.$route.query.role
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchUsers()
    }, 2000),
    fetchUsers(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultRole){ payload.role = this.defaultRole }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("userList/fetchUsers", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete a user",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
    exportClients() {
      const payload = {
        url: `/users/export-excel?role=${this.defaultRole}`,
        filename: 'users_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    sendInvitation(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.popupModalInvitation = true
    },
    initializeAll(){
      this.popupModalInvitation = false
    }
  },
  created() {
    this.refreshQueryRole()
    if(!this.defaultRole){this.fetchUsers()}
    this.$store.dispatch('meeting/fetchAllMeetings')
  }
}

</script>

